import React, { useState, useEffect } from 'react';
import {
  Card, Col, Form, Row,
} from 'react-bootstrap';
import Select from 'react-select';

import { getSchoolApplication } from 'hooks/getSchoolApplication';
import getSchoolInfo from 'hooks/getSchoolInfo';
import getSchoolsByCountry from 'hooks/getSchoolsByCountry';
import { getSchoolIdFromStorage } from 'dist/Storage/localStorageMethods';

import ActiveReceivedApplications from './sections/ActiveReceivedApplications';
import ApplicationsUnderStudy from './sections/ApplicationsUnderStudy';
import ApplicationsInterview from './sections/ApplicationsInterview';
import ApplicationsCompleted from './sections/ApplicationsCompleted';
import DisabledApplications from './sections/DisabledApplications';
import ApplicationsImpossible from './sections/ApplicationsImpossible';

import styles from './styles.module.css';

export default function ReceivedApplications() {
  const [selectedCountry, setSelectedCountry] = useState();
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [schoolId, setSchoolId] = useState();
  const [schoolData, setSchoolData] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [applicationData, setApplicationData] = useState();

  async function loadPostulations() {
    const applications = await getSchoolApplication(schoolId);

    const newApplication = applications.filter(
      (application) => application?.postulation_status?.id === 1,
    );

    const inAnalysis = applications.filter(
      (application) => application?.postulation_status?.id === 2,
    );

    const applicationsNotApplied = applications.filter(
      (application) => application.postulated === false,
    );

    const denied = applications.filter(
      (application) => (
        application?.postulation_status?.id === 3
        || application?.postulation_status?.id === 7
        || application?.postulation_status?.id === 6),
    );

    const interviewProcess = applications.filter(
      (application) => application?.postulation_status?.id === 4,
    );

    const accepted = applications.filter(
      (application) => application?.postulation_status?.id === 5,
    );

    const data = {
      newApplication,
      inAnalysis,
      denied,
      interviewProcess,
      conclusion: [
        ...accepted,
        ...denied,
      ],
      applicationsNotApplied,
    };

    return setApplicationData(data);
  }

  useEffect(() => {
    const schoolIdFromUser = getSchoolIdFromStorage();
    if (schoolIdFromUser) {
      setSchoolId(schoolIdFromUser);
    } else {
      setIsAdmin(true);
      setSelectedCountry(101);
    }
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      getSchoolsByCountry(selectedCountry).then(setSchoolOptions);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (schoolId) {
      getSchoolInfo(schoolId).then((info) => {
        setSchoolData(info);
        loadPostulations();
      });
    }
  }, [schoolId]);

  if (!isAdmin && !schoolId) return <p>Cargando...</p>;
  if (isAdmin && schoolId && !schoolData) return <p>Cargando...</p>;

  function handleSchoolSelectChange(e) {
    return setSchoolId(e.value.id);
  }

  function handleCountrySelectChange(e) {
    return setSelectedCountry(e.value);
  }

  const buildCommonPortion = () => (
    <>
      {isAdmin && (
        <>
          <Card style={{ margin: '10px 30px' }}>
            <Row style={{ padding: '10px' }}>
              <Col md="2">
                <Form.Label>País</Form.Label>
                <Select
                  placeholder="País"
                  options={[
                    { value: 102, label: 'Argentina' },
                    { value: 101, label: 'Uruguay' },
                    { value: 105, label: 'Perú' },
                  ]}
                  name="country"
                  onChange={(e) => handleCountrySelectChange(e)}
                />
              </Col>
              <Col md="5">
                <Form.Label>ID VACANTED o Nombre Institución</Form.Label>
                <Select
                  placeholder="ID - Nombre"
                  options={schoolOptions.map((school) => ({ value: school, label: `${school.id} - ${school.name}` }))}
                  name="schoolId"
                  onChange={(e) => handleSchoolSelectChange(e)}
                />
              </Col>
            </Row>
          </Card>
        </>
      )}
      <h1>Postulaciones recibidas</h1>
      <p>
        A continuación se muestran las postulaciones
        recibidas de familias a través de VACANTED™ para
        {' '}
        {schoolData?.name || 'su Centro Educativo'}
      </p>
    </>
  );

  if (!applicationData) {
    return (
      <div className={styles.bodyContainer}>
        {buildCommonPortion()}
        <Card className={styles.card}>
          <h3>No hay postulaciones para este centro.</h3>
        </Card>
      </div>
    );
  }

  if (!schoolData.postulate) {
    return (
      <div className={styles.bodyContainer}>
        {buildCommonPortion()}
        <Card className={styles.card}>
          <DisabledApplications
            data={applicationData.newApplication}
          />
        </Card>
        <Card className={styles.card}>
          <ApplicationsImpossible
            data={applicationData.applicationsNotApplied}
          />
        </Card>
      </div>
    );
  }

  return (
    <div className={styles.bodyContainer}>
      {buildCommonPortion()}
      <Card className={styles.card}>
        <ActiveReceivedApplications
          data={applicationData.newApplication}
          reloadData={() => loadPostulations()}
          isAdmin={isAdmin}
        />
      </Card>
      <Card className={styles.card}>
        <ApplicationsUnderStudy
          data={applicationData.inAnalysis}
          reloadData={() => loadPostulations()}
        />
      </Card>
      <Card className={styles.card}>
        <ApplicationsInterview
          data={applicationData.interviewProcess}
          reloadData={() => loadPostulations()}
        />
      </Card>
      <Card className={styles.card}>
        <ApplicationsCompleted
          data={applicationData.conclusion}
          reloadData={() => loadPostulations()}
        />
      </Card>
      <Card className={styles.card}>
        <ApplicationsImpossible
          data={applicationData.applicationsNotApplied}
          reloadData={() => loadPostulations()}
        />
      </Card>
    </div>
  );
}
