import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { getDataFromLocalStorage } from 'dist/Storage/localStorageMethods';
import { useUserData } from 'Contexts/UserContext';

const AdminContext = createContext();

function AdminContextProvider({ children }) {
  const filtersInitialState = {
    schoolName: '',
    email: '',
    schoolId: '',
    status: '',
  };

  const payloadInitialState = {
    email: '',
    id: '',
    name: '',
    password: '',
    role: '',
    schoolId: '',
    schoolName: '',
  };

  const [payload, setPayload] = useState(payloadInitialState);
  const [filteredData, setFilteredData] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [filters, setFilters] = useState(filtersInitialState);
  const [loggedUser, setLoggedUser] = useState({ email: 'emb@vacanted.com.ar' });
  const [country, setCountry] = useState();
  const [schoolId, setSchoolId] = useState();

  const { userData } = useUserData();
  const { loginStatus } = userData;

  useEffect(async () => {
    if (loginStatus) {
      const userInLocalStorage = await getDataFromLocalStorage('vacanted');
      setLoggedUser(userInLocalStorage.user);
    }
  }, [loginStatus]);

  useEffect(() => {
    setCountry({ name: 'Uruguay', id: 101 });
  }, [loggedUser]);

  const contextData = {
    filteredData,
    setFilteredData,
    payload,
    setPayload,
    payloadInitialState,
    isCreating,
    setIsCreating,
    isEditing,
    setIsEditing,
    isFiltering,
    setIsFiltering,
    filters,
    setFilters,
    filtersInitialState,
    loggedUser,
    country,
    schoolId,
    setSchoolId,
  };

  return (
    <AdminContext.Provider value={contextData}>
      {children}
    </AdminContext.Provider>
  );
}

export default AdminContextProvider;

AdminContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function useAdminData() {
  const context = useContext(AdminContext);
  return context;
}
